var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-apple")]);

}
var staticRenderFns = []

export { render, staticRenderFns }